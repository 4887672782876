import React from 'react';

const ParticipantsInfo = ({ language }) => {
  if (language === 'it') {
    return (
      <>
        <p>
          Ti verranno poste una serie di domande, alle quali dovrai rispondere
          nel modo più rapido e intuitivo possibile. Non soffermarti troppo a
          lungo a valutare le alternative. Se nessuna delle risposte presentate
          corrisponde pienamente al tuo pensiero, scegli quella che si avvicina
          di più.
        </p>
        <p className='middle'>
          Rispondi alle domande il più sinceramente possibile.
        </p>
        <p>
          <b>Nota terminologica importante:</b> Il questionario è aggiornato
          alle più recenti evoluzioni della ricerca sullo sviluppo delle
          organizzazioni e delle carriere. Tiene dunque conto ad esempio del
          passaggio dall'impiego a tempo pieno al un tipo di carriera{' '}
          <b>"a portafoglio"</b>, nonché delle nuove professioni e modalità
          lavorative legate allo sviluppo tecnologico. La terminologia
          utilizzata nel questionario è in linea con tali sviluppi. <b>“Io”</b>{' '}
          si riferisce a come ti vedi in quanto persona, dal punto di vista
          professionale e privato. <b>“Contatti interpersonali”</b> sono le
          persone con cui lavori quotidianamente. Se sei un dipendente, può
          trattarsi del tuo team; se sei un freelance o un imprenditore, dei
          tuoi colleghi. <b>“Comunità”</b>, se sei dipendente, può essere la tua
          organizzazione; se sei un freelance o un imprenditore, potrebbero
          essere le diverse aziende di cui fai parte o i clienti con cui lavori.
          <b>“Ecosistema”</b> può essere il settore in cui opera la tua azienda
          o gli ambiti in cui lavori, al di fuori dei confini
          dell'organizzazione.
        </p>
      </>
    );
  }

  return (
    <>
      <p>
        You will be asked a set of questions. Please answer as intuitively and
        quickly as possible. Don't overthink the choices. If none of the choices
        apply fully, select the closest that applies.
      </p>
      <p className='middle'>
        Please answer to the questions as truthfully as possible.
      </p>
      <p>
        <b>Important notice on terminology:</b> This questionnarie considers the
        latest developments in the field of careers and organusational
        developments. As such is taking into account (e.g. the shifts into
        portfolio careers versus full-time employment or new technology related
        professions and ways of working). The terminology used in this
        questionnaire is adopted to these new organisational developments.
        <b>"Self"</b> refers to who you see yourself to be as person (both
        professionally and privately). <b>"Interpersonal Connections"</b> are
        the people you work with on a daily basis. If you are employed this can
        be your team, if you are a freelancer/entrepreneur this can be your
        colleagues. <b>"Community"</b> if you are employed this can be your
        organization, if you are freelancer/entrepreneur it might be the
        different companies that you are part of or clients you work with.
        <b>"Ecosystem"</b> can be the industry your company is part of or areas
        you are working in, these areas go beyond just organisations.
      </p>
    </>
  );
};

export default ParticipantsInfo;
