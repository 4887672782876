import React from "react";
import { ErrorMessage } from "formik";
import Radio from "components/UI/Radio/Radio";

const RadioStep = ({ question, name, field, options, form, values }) => {
  const fieldName = name || field.name;

  const { setFieldValue } = form;

  const setSelected = (value) => {
    setFieldValue(fieldName, value);
  };

  return (
    <>
      <div className="form-question">{question}</div>
      <div className="radio__container container">
        <div className="radio__list">
          {options?.map((option) => (
            <Radio
              key={option.id}
              value={option.id}
              text={option.option}
              name={fieldName}
              values={values}
              selected={values[fieldName]}
              onChange={setSelected}
            />
          ))}
        </div>
        <ErrorMessage
          name={fieldName}
          render={(msg) => <p className="error-message">{msg}</p>}
        />
      </div>
    </>
  );
};

export default RadioStep;
