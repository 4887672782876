import React from "react";
import { ErrorMessage } from "formik";
import Checkbox from "components/UI/Checkbox/Checkbox";

const Multiselect = ({ name, field, question, options, values, form }) => {
  const fieldName = name || field.name;

  const { setFieldValue } = form;

  const toggleChecked = (name, value, field) => {
    if (field.value.includes(value)) {
      setFieldValue(name, [...field.value.filter((item) => item !== value)]);
    } else {
      setFieldValue(name, [...field.value, value]);
    }
  };

  return (
    <>
      <div className="form-question">{question}</div>
      <div className="checkbox__container container">
        {options?.map((option) => (
          <Checkbox
            field={field}
            key={option.id}
            name={fieldName}
            label={option.option}
            value={option.id}
            values={values}
            onChange={toggleChecked}
          />
        ))}

        <ErrorMessage
          name={fieldName}
          render={(msg) => <p className="error-message">{msg}</p>}
        />
      </div>
    </>
  );
};

export default Multiselect;
