import React from "react";
import { ErrorMessage } from "formik";
import Radio from "components/UI/Radio/Radio";
import { rangeOptionsColors } from "helpers/rangeOptionsColors";

const Range = ({
  question,
  name,
  field,
  form,
  values,
  count,
  max_scale_text,
  min_scale_text,
}) => {
  const fieldName = name || field.name;

  const { setFieldValue } = form;

  const setSelected = (value) => {
    setFieldValue(fieldName, value);
  };

  return (
    <>
      <div className="form-question">{question}</div>
      <div className="range__container container">
        <div className="criteria min mobile">{min_scale_text}</div>
        <div className="radio__list">
          {Array.from(Array(count)).map((x, index) => (
            <Radio
              key={index}
              value={index}
              name={fieldName}
              selected={values[fieldName]}
              onChange={setSelected}
              showValue={true}
              color={rangeOptionsColors[index].color}
              backgroundColor={rangeOptionsColors[index].backgroundColor}
            />
          ))}
        </div>
        <div className="criteria max mobile">{max_scale_text}</div>
        {max_scale_text && min_scale_text && (
          <div className="radio__criterias">
            <div className="criteria min">{min_scale_text}</div>
            <div className="criteria max">{max_scale_text}</div>
          </div>
        )}
        <ErrorMessage
          name={fieldName}
          render={(msg) => <p className="error-message">{msg}</p>}
        />
      </div>
    </>
  );
};

export default Range;
